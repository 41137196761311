
















































import { Component, Vue, Prop } from "vue-property-decorator";
import SvgIcon from "@/components/SvgIcon/SvgIcon.vue";
import Link from "@/components/Link/Link.vue";
import OpeningTimes from "@/components/OpeningTimes/OpeningTimes.vue";
import OpeningTimesCurrent from "@/components/OpeningTimes/OpeningTimesCurrent.vue";

@Component({
	components: {
		SvgIcon,
		Link,
		OpeningTimes,
		OpeningTimesCurrent
	}
})
export default class InfoBox extends Vue {
	private address: object = this.$store.getters.meta.info.address;
	private translations: any = this.$store.getters.meta.infobox;
	private isExtended: boolean = false;

	get routeUrl() {
		return `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
			Object.values(this.address).join(" ")
		)}`;
	}
}
