











import { Component, Vue, Watch } from "vue-property-decorator";
import hash from "object-hash";

@Component({
	metaInfo() {
		// TODO refactor / remove dublicates
		const route: string = this.$route.params.route;
		const page: string =
			route && this.$store.getters.isPageAvailable(route)
				? route
				: "home";

		const { title } = this.$store.getters.page(page).meta;

		return {
			title: `Bruch und Söhne GmbH & Co. KG - ${title}`
		};
	}
})
export default class PageTemplate extends Vue {
	private components: object = {};

	mounted() {
		this.loadComponents();
	}

	@Watch("$route")
	onRouteChanged(to: any, from: any) {
		this.loadComponents();
	}

	loadComponents() {
		const route: string = this.$route.params.route;
		const page: string =
			route && this.$store.getters.isPageAvailable(route)
				? route
				: "home";

		this.components = this.$store.getters.page(page).components;
	}

	hash(obj: object) {
		return hash(obj);
	}
}
