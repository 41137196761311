import Vue from "vue";
import Vuex from "vuex";
import data from "@/data";

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		data: data as any
	},
	mutations: {},
	actions: {},
	getters: {
		// store.getters.home
		title: state => state.data.meta.info.title,
		meta: state => state.data.meta,
		nav: state => state.data.meta.header.nav,
		isPageAvailable: state => (page: string) => {
			return Object.keys(state.data.pages).includes(page);
		},
		page: state => (page: string) => {
			return state.data.pages[page];
		},
		currentTimePeriod: state => {
			const d = new Date(); // current time
			const day = (d.getDay() + 6) % 7; // add 6 as sunday is last entry of our data model
			const hours = d.getHours();
			const mins = d.getMinutes();

			// get todays time
			const todaysTimes = Object.values(
				state.data.meta.info.openingTimes
			)[day] as any[];

			// build up array of time periods where period = {from: {hours: 8, mins: 30}, to:{hours: 12, mins: 30}}
			const periods = todaysTimes.map((period: any) => {
				return Object.keys(period).reduce(
					(acc: object, cur: string) => {
						const time = period[cur].split(":");
						return {
							...acc,
							[cur]: { hours: +time[0], mins: +time[1] }
						};
					},
					{}
				);
			});

			// check all time periods if they hit the current time
			const isOpenArray = periods.map((p: any, i: number) => {
				return (
					(p.from.hours < hours && hours < p.to.hours) || // 08:30-12:30 open => currently 9:13 	=> 	8 < 9 && 9 < 12		=> open
					(p.from.hours === hours && p.from.mins <= mins) || // 08:30 opening 	=> currently 8:45 	=> 	8 === 8 && 30 <= 45  =>  open
					(p.to.hours === hours && p.to.mins > mins)
				); // 12:30 closing 	=> currently 12:15 	=> 	12 === 12 && 30 > 15  =>  open
			});

			// check if some entry returned true
			const indexOfOpenTime = isOpenArray.indexOf(true);

			return {
				isOpen: indexOfOpenTime >= 0, // if not found it is -1
				period: todaysTimes[indexOfOpenTime]
			};
		}
	}
});
