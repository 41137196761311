


















import { Component, Vue, Prop } from "vue-property-decorator";
import TeaserItem from "./TeaserItem.vue";

@Component({
	props: {
		data: Object
	},
	components: {
		TeaserItem
	}
})
export default class Teaser extends Vue {}
