
























import { Component, Vue, Prop } from "vue-property-decorator";
import Link from "@/components/Link/Link.vue"; // @ is an alias to /src

@Component({
	components: {
		Link
	}
})
export default class TeaserItem extends Vue {
	private hover = false;

	@Prop(Object)
	private item!: any;

	openUrl() {
		(this.$refs.link as any).$el.querySelector("a").click(); // parse to VueComponent does not work..
	}
}
