












import { Component, Vue } from "vue-property-decorator";
import ImageTeaserItem from "./ImageTeaserItem.vue";

@Component({
	props: {
		data: Object
	},
	components: {
		ImageTeaserItem
	}
})
export default class ImageTeaser extends Vue {}
