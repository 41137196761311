






import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class CopyText extends Vue {
	@Prop(Object)
	private data!: any;

	get text() {
		return Array.isArray(this.data.text)
			? this.data.text.join("")
			: this.data.text;
	}
	get spacing() {
		return `space-${this.data.spacing || "small"}`;
	}
}
