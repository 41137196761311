









import { Component, Vue } from "vue-property-decorator";
import Footer from "@/components/Footer/Footer.vue";
import Header from "@/components/Header/Header.vue";
import InfoBox from "@/components/InfoBox/InfoBox.vue";

@Component({
	components: {
		Header,
		Footer,
		InfoBox
	},
	metaInfo: {
		title: "Bruch und Söhne GmbH & Co. KG"
	}
})
export default class App extends Vue {}
