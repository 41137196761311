




























import { Component, Vue, Prop } from "vue-property-decorator";
import { Fragment } from "vue-fragment";
import Link from "@/components/Link/Link.vue";

const CONTACT_SEEN = "contact_seen";

@Component({
	components: {
		Link
	}
})
export default class ContactDetail extends Vue {
	@Prop(String)
	private name!: string;

	@Prop(String)
	private position!: string;

	@Prop(String)
	private tel!: string;

	@Prop(String)
	private mobile?: string;

	@Prop(String)
	private email?: string;

	get formattedTel(): String {
		return this.tel.replace(new RegExp("( |/|-)", "g"), "");
	}

	get formattedMobile(): String {
		return this.mobile
			? this.mobile.replace(new RegExp("( |/|-)", "g"), "")
			: "";
	}
}
