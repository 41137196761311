















import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class Link extends Vue {
	@Prop(String)
	private url!: string;

	@Prop(String)
	private text!: string;

	@Prop({
		type: String,
		default: "span"
	})
	private tag?: string;

	@Prop(String)
	private className?: string;

	@Prop(String)
	private icon?: string;

	@Prop(Boolean)
	private hover?: boolean;

	@Prop(Boolean)
	private preventScrollToTop?: boolean; // TODO

	get external() {
		return new RegExp("^(https?:)?//").test(this.url);
	}

	scrollToTop() {
		window.scrollTo({
			top: 0,
			behavior: "smooth"
		});
	}
}
