










import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class SvgIcon extends Vue {
	@Prop(String)
	private name!: string;

	@Prop(String)
	private title: string | undefined;

	@Prop(String)
	private additionalClass: string | undefined;

	get iconPath() {
		let icon = require(`@/assets/icons/${this.name}.svg`);

		if (Object.prototype.hasOwnProperty.call(icon, "default")) {
			icon = icon.default;
		}

		return icon.url;
	}

	get className() {
		return `svg-icon svg-icon--${this.name} ${
			this.additionalClass ? this.additionalClass : ""
		}`;
	}
}
