














import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class OpeningTimesCurrent extends Vue {
	private translations: any = this.$store.getters.meta.infobox;

	@Prop(Object)
	private className?: string;

	get currentTime() {
		return this.$store.getters.currentTimePeriod;
	}
}
