









import { Component, Vue } from "vue-property-decorator";
import FooterNav from "./FooterNav.vue";
import FooterLegal from "./FooterLegal.vue";

@Component({
	components: {
		FooterNav,
		FooterLegal
	}
})
export default class Footer extends Vue {}
