











































import { Component, Vue, Prop } from "vue-property-decorator";
import ContactDetail from "@/components/ContactDetail/ContactDetail.vue";
import Link from "@/components/Link/Link.vue";

const CONTACT_SEEN = "contact_seen";

@Component({
	components: {
		ContactDetail,
		Link
	}
})
export default class Contact extends Vue {
	@Prop(Object)
	private data!: any;

	private infosVisible = false;
	private timeout = -1;
	private inViewport: boolean = !this.data.moveInAnimation;

	mounted() {
		if (this.data.moveInAnimation) {
			this.inViewport = true;
		}
		// } else {
		// 	this.onMoveInFinished();
		// }
	}

	// get infosInitiallyVisible() {
	// 	return !sessionStorage.getItem(CONTACT_SEEN) && this.data.visibleTime;
	// }

	// onMoveInFinished() {
	// 	if (this.infosInitiallyVisible) {
	// 		this.infosVisible = true;

	// 		sessionStorage.setItem(CONTACT_SEEN, "seen");
	// 		this.timeout = window.setTimeout(() => {
	// 			this.infosVisible = false;
	// 		}, this.data.visibleTime * 1000);
	// 	}
	// }

	toggleInfos() {
		this.infosVisible = !this.infosVisible;
		this.clearTimeout();
	}

	clearTimeout() {
		clearTimeout(this.timeout);
	}
}
