



















import { Component, Vue, Prop } from "vue-property-decorator";
import Link from "@/components/Link/Link.vue";

@Component({
	components: {
		Link
	},
	model: {
		prop: "isOpen",
		event: "close"
	}
})
export default class HeaderNav extends Vue {
	@Prop(Boolean)
	private isOpen!: boolean;

	private entries = this.$store.getters.nav;
}
