































import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class Stage extends Vue {
	@Prop(Object)
	private data!: any;

	get delimiter() {
		return this.data.headline && this.data.subline ? " - " : "";
	}
	get isVideo() {
		return !!this.data.videoSrc;
	}
	get videoType() {
		return this.isVideo
			? `video/${this.data.videoSrc.split(".").pop()}`
			: "";
	}
}
