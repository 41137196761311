export default {
	meta: require("@/assets/meta.json"),

	pages: {
		home: require("@/assets/pages/home.json"),
		about: require("@/assets/pages/about.json"),
		kontakt: require("@/assets/pages/kontakt.json"),
		standorte: require("@/assets/pages/standorte.json"),

		wertstoffe: require("@/assets/pages/wertstoffe.json"),
		entsorgung: require("@/assets/pages/entsorgung.json"),
		demontage: require("@/assets/pages/demontage.json"),

		impressum: require("@/assets/pages/impressum.json"),
		zahlungsbedingungen: require("@/assets/pages/zahlungsbedingungen.json"),
		einkaufsbedingungen: require("@/assets/pages/einkaufsbedingungen.json"),

		demo: require("@/assets/pages/demo.json")
	}
};
