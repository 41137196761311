
































import { Component, Vue, Prop } from "vue-property-decorator";
import Link from "@/components/Link/Link.vue"; // @ is an alias to /src

@Component({
	components: {
		Link
	}
})
export default class ImageTeaserItem extends Vue {
	private hover = false;

	@Prop(Object) private item!: any;

	get paragraphs() {
		// TODO compare with copytext => might use same solution here
		return Array.isArray(this.item.text)
			? this.item.text
			: [this.item.text];
	}

	get text() {
		return Array.isArray(this.item.text)
			? this.item.text.join("")
			: this.item.text;
	}

	openUrl() {
		(this.$refs.link as any).$el.querySelector("a").click(); // parse to VueComponent does not work..
	}
}
