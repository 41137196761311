
























import { Component, Vue, Prop } from "vue-property-decorator";
import SvgIcon from "@/components/SvgIcon/SvgIcon.vue";
import Link from "@/components/Link/Link.vue";

@Component
export default class OpeningTimes extends Vue {
	private openingTimes: object = this.$store.getters.meta.info.openingTimes;
	private asterisk: object = this.$store.getters.meta.info.openingTimesAsterisk;
	private translations: any = this.$store.getters.meta.infobox;

	get days() {
		return Object.keys(this.openingTimes);
	}
}
