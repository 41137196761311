import Vue from "vue";

import Stage from "@/components/Stage/Stage.vue";
import SvgIcon from "@/components/SvgIcon/SvgIcon.vue";
import Teaser from "@/components/Teaser/Teaser.vue";
import ImageTeaser from "@/components/ImageTeaser/ImageTeaser.vue";
import CopyText from "@/components/CopyText/CopyText.vue";
import MediaGallery from "@/components/MediaGallery/MediaGallery.vue";
import Contact from "@/components/Contact/Contact.vue";

// make main/container components available in whole Application
Vue.component("Stage", Stage);
Vue.component("SvgIcon", SvgIcon);
Vue.component("Teaser", Teaser);
Vue.component("ImageTeaser", ImageTeaser);
Vue.component("CopyText", CopyText);
Vue.component("MediaGallery", MediaGallery);
Vue.component("Contact", Contact);
