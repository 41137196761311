import "normalize.css";
import "./less/utils.less";
import "./components";

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VBodyScrollLock from "v-body-scroll-lock";
import Fragment from "vue-fragment";
import VueMeta from "vue-meta";

Vue.use(VBodyScrollLock);
Vue.use(Fragment.Plugin);
Vue.use(VueMeta, {
	refreshOnceOnNavigation: true
});

Vue.config.productionTip = false;

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount("#app");
