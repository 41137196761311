
















import { Component, Vue, Prop } from "vue-property-decorator";
import Link from "@/components/Link/Link.vue";

@Component({
	components: {
		Link
	}
})
export default class FooterLegal extends Vue {
	private name: object = this.$store.getters.title;
	private links: object = this.$store.getters.meta.footer.legal;
}
