






import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class HeaderPrices extends Vue {}
