



























import { Component, Vue, Prop } from "vue-property-decorator";
import Logo from "@/components/Logo/Logo.vue";
import OpeningTimesCurrent from "@/components/OpeningTimes/OpeningTimesCurrent.vue";
import OpeningTimes from "@/components/OpeningTimes/OpeningTimes.vue";
import HeaderPrices from "./HeaderPrices.vue";
import HeaderNavIcons from "./HeaderNavIcons.vue";

@Component({
	components: {
		Logo,
		OpeningTimes,
		OpeningTimesCurrent,
		HeaderPrices,
		HeaderNavIcons
	}
})
export default class Header extends Vue {
	private navOpen = false;
}
