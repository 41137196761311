import Vue from "vue";
import Router from "vue-router";
import PageTemplate from "./views/PageTemplate.vue";

Vue.use(Router);

export default new Router({
	mode: "history",
	base: process.env.BASE_URL,
	routes: [
		// {
		// 	path: "/about",
		// 	name: "about",
		// 	// route level code-splitting
		// 	// this generates a separate chunk (about.[hash].js) for this route
		// 	// which is lazy-loaded when the route is visited.
		// 	component: () =>
		// 		import(/* webpackChunkName: "about" */ "./views/About.vue")
		// },
		// dynamic route should be last entry
		{
			path: "/:route?",
			name: "page template",
			component: PageTemplate
		}
	]
});
