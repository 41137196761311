













import { Component, Vue, Prop } from "vue-property-decorator";
import Link from "@/components/Link/Link.vue";
import Logo from "@/components/Logo/Logo.vue";

@Component({
	components: {
		Link,
		Logo
	}
})
export default class FooterNav extends Vue {
	private name: object = this.$store.getters.title;
	private nav: object = this.$store.getters.meta.footer.nav;
}
