





















































import { Component, Vue, Prop } from "vue-property-decorator";
import { Carousel, Slide } from "vue-carousel";

@Component({
	components: {
		carousel: Carousel,
		slide: Slide
	}
})
export default class MediaGallery extends Vue {
	@Prop(Object)
	private data!: any;

	private selectedSlide = 0;
	private thumbSlideCount = 6;
	private autoplayActive = true;

	get selectionPos() {
		return `calc(100% / ${this.thumbSlideCount} * ${this.selectedSlide})`;
	}
}
